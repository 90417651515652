@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body {
    font-family: 'Source Code Pro', monospace;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Button_button__c9naJ {
    color: white;
    background-color: transparent;
    padding: 1em 2em;
    border: 2px solid white;
    border-radius: 5px;
    font-family: 'Source Code Pro', monospace;
    cursor: pointer;
    margin-bottom: 1em;
    margin-right: 1em;
    width: 100%;
}

.Button_button__c9naJ:last-of-type {
    margin-right: 0;
}

.Button_button__c9naJ:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.Button_button__c9naJ:active {
    background-color: white;
    color: #323232;
}

.Button_active__1HCX4 {
    background-color: white;
    color: black;
}
.DataStream_ul__1_u-j {
    position: absolute;
    right: -40%;
    top: 0;
    margin-top: 0;
    list-style: none;
    padding-left: none;
    color: #03A062;
    height: 100%;
    overflow: hidden;
}

.DataStream_item__3Ph8M {
    opacity: 0.4;
}

@media (max-width: 786px) {
    .DataStream_ul__1_u-j { right: 0 }
}

.DataStream_vector__3IUYG {
    display: inline-block;
    padding-right: 0.5em;
    min-width: 4em;
    text-align: right;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.DataStream_vector__3IUYG:last-of-type {
    padding-right: 0;
}

.DataStream_label__3o-u6 {
    color: white;
    text-align: center;
    display: inline-block;
    padding-right: 0.5em;
    min-width: 4em;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.Qubit_container__1sJ7y {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;
    position: relative;
    margin: 0 auto;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Qubit_label__3Xtz1 {
    position: absolute;
    cursor: pointer;
    z-index: 100;
}

.Qubit_label0__3WCv8 { top: 0 }
.Qubit_label1__3xVeR { bottom: 0 }

.Qubit_labelplus__2fnRn, .Qubit_labeli__32-nd { top: 60% }
.Qubit_labelminus__3Odm7, .Qubit_labelminusi__3_t-t { bottom: 57.5%; -webkit-transform: scale(0.75); transform: scale(0.75) }

.Qubit_labelminus__3Odm7 { right: 1em }
.Qubit_labelminusi__3_t-t { left: 0.5em }

.Qubit_labelplus__2fnRn { left: -1em }
.Qubit_labeli__32-nd { right: -1em }
.SliderGroup_sliders__1Nehz {
    width: 100%;
    margin-bottom: 1.25em;
}

.SliderGroup_container__1xV9N {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.SliderGroup_slider__2QgSl {
    width: 80%;
}
.SliderGroup_h2__3hah9 {
    cursor: pointer;
}
.SliderGroup_label__OjMN2 {
    width: 20%;
    color: #FFF;
    font-size: 0.65em;
    position: relative;
    right: -2em;
    cursor: pointer;
}
.SliderGroup_labelInvert__rgs0C {
    text-align: right;
    left: -2em;
    order: -1;
}
.SliderGroup_labelSelected__1nPo4 {
    background-color: blue;
}
.SliderGroup_textRight__3bYAE {
    text-align: right;
}

@media (max-width: 1400px) {
    .SliderGroup_slider__2QgSl { width: 70%;}
    .SliderGroup_label__OjMN2 { width: 30%;}
}

.SliderGroup_title__3horV {
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: bold;
}
.Controller_container__3ue-Q {
    padding: 0 0 4em 0;
    display: flex;
    justify-content: center;
}

.Controller_buttons__1nMk7 {
    min-width: 15em;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
}

@media (max-width: 600px) {
    .Controller_buttons__1nMk7 { width: 100% }
}

.Controller_sliders__2NJtA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Controller_sliders__2NJtA {
    flex-basis: 20%;
}
.Controller_qubit__2Y4Dj {
    flex-basis: 60%;
    position: relative;
    display: flex;
    justify-content: center;
}

.Controller_qubitFW__Obwdj {
    flex-basis: 100%;
}

@media (max-width: 1100px) {
    .Controller_sliders__2NJtA {
        display: none;
    }
}
.Select_container__2Nje4 {
    margin-bottom: 0.75em;
}

.Select_select__3-XzH {
    width: 100%;
    padding: 1em;
    font-weight: 900;
    font-family: 'Source Code Pro', monospace;
}
.Select_select__3-XzH:focus {
    outline: black;
}
.Input_input__hNVKP {
    width: calc(100% - 2.35em);
    padding: 1em;
    font-weight: 900;
    font-family: 'Source Code Pro', monospace;
}
.Input_input__hNVKP:focus {
    outline: black;
}
.Presets_buttons__2eVoJ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1;
}

.Presets_button__PPikA {
    flex-basis: calc(25% - (3em/4));
    color: white;
    background-color: transparent;
    padding: 1em;
    border: 2px solid white;
    border-radius: 5px;
    font-family: 'Source Code Pro', monospace;
    cursor: pointer;
    margin-bottom: 1em;
    opacity: 0.1;
}

.Presets_button__PPikA:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.Presets_active__AoNdh {
    background-color: white;
    color: black;
}

.Presets_exists__3voe2 {
    opacity: 1;
}
.SidePanel_toggle__1q08D {
    width: 5em;
    position: absolute;
    left: -4.4em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    text-align: center;
    z-index: 100;
    padding: 0.5em;
    border: 2px solid white;
    background-color: #323232;
    cursor: pointer;
    border-radius: 0 0 5px 5px;
    opacity: 0.5;
}

.SidePanel_toggle0__3TIwi {
    top: 3em;
}

.SidePanel_toggle1__3_FZf {
    top: 9.1em;   
}

.SidePanel_toggleActive__2kUVm {
    border-top: 3px solid #323232;
    z-index: 10;
    opacity: 1;
}

.SidePanel_sidePanel__3x1lC {
    position: relative;
    top: 0;
    bottom: 0;
    background-color: #323232;
    /* border-left: 2px solid white; */
    min-height: 100vh;
}

@media (max-width: 786px) {
    .SidePanel_sidePanel__3x1lC {
        display: none;
    }
}

.SidePanel_sidePanel__content__3ghJv {
    background-color: #323232;
    position: absolute;
    top: 0;
    min-width: 20em;
    padding: 2em;
    min-height: calc(100% - 4em);
    border-left: 2px solid white;
}

.SidePanel_contentActive__3lAM7 {
    /* overflow-y: scroll; */
    z-index: 200;
}

.SidePanel_sidePanelOpen__5Qz5X {
    position: absolute;
    right: 0;
    width: 24em;
    transition: 0.5s all ease-out;
}

.SidePanel_sidePanelClosed__2EcoM {
    width: 0;
}

.SidePanel_sidePanelMobile__1p1-N {
    @media (max-width: 1200px) {
        position: absolute;
        right: 0;
        max-width: calc(100vw - 34px);
    }
}

.SidePanel_buttons__2K0HQ {
    width: 100%;
    margin-bottom: 0.75em;
    display: flex;
}

.SidePanel_measureContainer__xoB7y {
    position: relative;
}

.SidePanel_checkboxLabel__1RYlp {
    font-size: 0.75em;
    position: absolute;
    top: -0.6em;
    right: 0;
}

.SidePanel_checkbox__3WMdu {
    -webkit-appearance:none;
    -webkit-transition: .10s;
    height:1.25em;
    width:1.25em;
    cursor:pointer;
    position:relative;
    border-radius:5px;
    background-color:transparent;
    border: 2px solid white;
    position: relative;
    top: 0.5em;
}
.SidePanel_checkbox__3WMdu:checked {
    background-color:white;
}
.SidePanel_checkbox__3WMdu:before, .SidePanel_checkbox__3WMdu:checked:before {
    position:absolute;
    top:-0.2em;
    left:0;
    width:100%;
    height:100%;
    line-height:1.5em;
    text-align:center;
    color: #323232;
}
.SidePanel_checkbox__3WMdu:checked:before {
    content: 'X';
}
.Loading_container__Yo_YE {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Loading_overlay__k7d8O {
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: blue;
    opacity: 0.75;
    z-index: -10;
}

.Loading_spinner__3LpUp {
    z-index: 10;
    height: 5em;
    width: 5em;
    -webkit-animation: Loading_rotation__26VL6 2s;
            animation: Loading_rotation__26VL6 2s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.Loading_responses__jHUE2 {
    list-style: none;
    padding-left: 0;
}

.Loading_response__3m_-Y {
    margin-top: 2em;
    font-weight: bold;
}

.Loading_response__3m_-Y:last-of-type::after {
    content: "_";
    -webkit-animation: Loading_flash__3DwuE 1s;
            animation: Loading_flash__3DwuE 1s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes Loading_rotation__26VL6 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes Loading_rotation__26VL6 {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}


@-webkit-keyframes Loading_flash__3DwuE {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
}


@keyframes Loading_flash__3DwuE {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
}
/* delete me */
html {
    background-color: #323232;
    color: #FFF;
    
}
body {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
}

#root {
    width: 100%;
}

.App {
    max-width: 1800px;
    margin: auto;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    justify-content: center;
    overflow-y: scroll;
}

.main {
    flex-basis: 100%;
    padding: 2em 4em;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

@media (max-width: 600px) {
    .main {
        padding: 2em;
    }
}

.info {
    padding-bottom: 3em;
}

@media (max-width: 600px) {
    .info {
        padding-bottom: 4em;
    }
}

.btn-mode {
    cursor: pointer;
    opacity: 0.5;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1em;
    font-family: inherit;
    padding: 0;
}

.btn-mode--active {
    opacity: 1;
}

@media (max-width: 600px) {
    .modes { display: none }
}

.btn-randomise {
    position: absolute;
    margin-top: 2em;
    width: 10em;
}

.btn-randomise button {
    margin-bottom: 0em;
}

@media (max-width: 600px) {
    .btn-randomise {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.fullscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
}

h1 {
    margin-top: 0;
}

h2, p {
    font-size: 1em;
    margin-top: 0;
}

.appInstructions {
    font-size: 0.75em;
}

#knob circle {
    fill: black;
}

#knob path {
    fill: #FFF;
}

svg linearGradient {
    fill: #FFF!important;
}

.rc-slider {
    padding: 0.75em 0;
}

.rc-slider-disabled {
    background-color: transparent;
    opacity: 0.5;
}
.rc-slider-rail {
    background-color: #FFF;
}

.rc-slider-handle {
    border-color: #FFF;
    background-color: black;
}

.rc-slider-track {
    background-color: black;
}

.download {
    padding: 1em 0 0 0;
}

@media (max-width: 600px) {
    .sliders {
        display: none;
    }
}
