.sliders {
    width: 100%;
    margin-bottom: 1.25em;
}

.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.slider {
    width: 80%;
}
.h2 {
    cursor: pointer;
}
.label {
    width: 20%;
    color: #FFF;
    font-size: 0.65em;
    position: relative;
    right: -2em;
    cursor: pointer;
}
.labelInvert {
    text-align: right;
    left: -2em;
    order: -1;
}
.labelSelected {
    background-color: blue;
}
.textRight {
    text-align: right;
}

@media (max-width: 1400px) {
    .slider { width: 70%;}
    .label { width: 30%;}
}

.title {
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: bold;
}